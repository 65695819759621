import { Route, Routes } from "react-router-dom";
import "./App.css";
import ThemeButton from "../ThemeButton/ThemeButton";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Projects from "../Projects/Projects";
import Skills from "../Skills/Skills";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";

function App() {
  const [{ theme }, toggleTheme] = useContext(ThemeContext);
  return (
    <div
      className="app"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <div className="app__container">
        <div className="app__themeButtonContainer">
          <ThemeButton onClick={toggleTheme} />
        </div>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Skills" element={<Skills />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
