import "./Footer.css";
import { SiApplemusic, SiLinkedin, SiGithub } from "react-icons/si";
import { motion } from "framer-motion";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";

const Footer = () => {
  const [{ isDark }] = useContext(ThemeContext);

  return (
    <motion.div
      className="footer"
      id={isDark ? "app_dark-menu" : ""}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ delay: 2, duration: 2.5 }}
    >
      <p className="footer__copyright">© 2024 Alon Goykhman.</p>
      <div className="footer__links">
        <a href="https://github.com/galon7" target="_blank" rel="noreferrer">
          <SiGithub
            className="footer__icon"
            id={isDark ? "app_dark-menu" : ""}
          />
        </a>
        <a
          href="https://www.linkedin.com/in/alon-g/"
          target="_blank"
          rel="noreferrer"
        >
          <SiLinkedin
            className="footer__icon"
            id={isDark ? "app_dark-menu" : ""}
          />
        </a>
      </div>
    </motion.div>
  );
};

export default Footer;
