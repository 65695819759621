export const projectsData = [
  {
    name: "IDF spokesman parody",
    image: require("../../images/projectIDF.png"),
    link: "https://idf.yeshmakot.com/",
    github: "https://github.com/galon7/projectIDF",
    description: `A parody website, generating random IDF announcements about the war in Gaza. 
    The images were generated using AI.`,
    tech: ["js", "cs", "re", "np"],
  },
  {
    name: "News explorer",
    image: require("../../images/news.png"),
    link: "https://news-explorer-frontend-galon7.vercel.app/",
    github: "https://github.com/galon7/news-explorer-api",
    description: `A website for searching and storing news articles from across the web. In this project I used React for the frontend. I used an external "News API" for getting news stories data
    and an API that I built for storing users and articles, using mongoDB.`,
    tech: ["js", "cs", "re", "ex", "mo", "np"],
  },

  {
    name: "Around the U.S.",
    image: require("../../images/around.png"),
    link: "https://react-around-auth.vercel.app/",
    github: "https://github.com/galon7/react-around-auth",
    description:
      "Around The U.S. An interactive page where users can add, remove, or like photos.",
    tech: ["js", "cs", "re", "ex", "mo", "np"],
  },
  {
    name: "Home Kitchen",
    image: require("../../images/kitchen.png"),
    link: "https://bar-amit.github.io/code-jam/",
    github: "https://github.com/galon7/code-jam/tree/develop",
    description:
      "A project for the Practicum 2021 October code-jam (an international Hackathon). Built together with Bar Amit and designer Konstantin Slizkov in less than 72 hours. Won 3rd place.",
    tech: ["js", "ht", "cs"],
  },
  {
    name: "Bicycle journey",
    image: require("../../images/from.png"),
    link: "https://galon7.github.io/web_project_3",
    github: "https://github.com/galon7/web_project_3",
    description:
      "A project about traveling across the US. In this very early project I learned how to use Figma, CSS grid/flexbox, external fonts, optimize images, publish on GitHub pages and more...",
    tech: ["ht", "cs"],
  },
];
