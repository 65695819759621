import "./Skills.css";
import { motion } from "framer-motion";
import ReactTooltip from "react-tooltip";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJs,
  faHtml5,
  faCss3,
  faReact,
  faNodeJs,
  faGitAlt,
  faNpm,
  faFigma,
  faBootstrap,
} from "@fortawesome/free-brands-svg-icons";
import { SiPostman, SiExpress, SiMongodb } from "react-icons/si";

const Skills = () => {
  const [{ isDark }] = useContext(ThemeContext);

  return (
    <div className="skills">
      <div className="skills__left">
        <h3 className="skills__text" id={isDark ? "app_dark-text" : ""}>
          Through my studies, I've gained a solid understanding of web
          development concepts. <br />I have built applications and RESTful APIs
          using JavaScript, React, Bootstrap, NodeJS, Express and MongoDB.
          <br />
          Some of my favorite development tools are git, VS Code, DevTools,
          Postman, MongoDB Compass, Figma, npm/Yarn, webpack.
        </h3>
      </div>
      <div className="skills__right">
        <motion.div
          animate={{
            scale: [1, 1.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            rotate: [0, 360, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faJs}
            className="skills__icon"
            style={{ color: "#ffeb3b" }}
            data-tip="JavaScript 💚"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            rotate: [0, 0, 360, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faHtml5}
            style={{ color: "#e44d26" }}
            className="skills__icon"
            data-tip="HTML5"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            rotate: [0, 0, 0, 360, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faCss3}
            style={{ color: "#3595cf" }}
            className="skills__icon"
            data-tip="CSS3"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1.75, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            rotate: [0, 0, 0, 0, 360, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faReact}
            style={{ color: "#5ed3f3" }}
            className="skills__icon"
            data-tip="ReactJS"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1.75, 1, 1, 1, 1, 1, 1, 1, 1],
            rotate: [0, 0, 0, 0, 0, 360, 0, 0, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faNodeJs}
            style={{ color: "#509941" }}
            className="skills__icon"
            data-tip="NodeJS"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1.75, 1, 1, 1, 1, 1, 1, 1],
            rotate: [0, 0, 0, 0, 0, 0, 360, 0, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <SiMongodb
            className="skills__icon"
            style={{ color: "#12a54f" }}
            data-tip="MongoDB"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1, 1.75, 1, 1, 1, 1, 1, 1],
            rotate: [0, 0, 0, 0, 0, 0, 0, 360, 0, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <SiExpress
            className="skills__icon"
            style={{ color: "#7e7e7e" }}
            data-tip="ExpressJS"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1, 1, 1.75, 1, 1, 1, 1, 1],
            rotate: [0, 0, 0, 0, 0, 0, 0, 0, 360, 0, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faGitAlt}
            style={{ color: "#ec2332" }}
            className="skills__icon"
            data-tip="Git"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1, 1, 1.75, 1, 1, 1, 1],
            rotate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 360, 0, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <SiPostman
            className="skills__icon"
            style={{ color: "#f76935" }}
            data-tip="Postman"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1.75, 1, 1, 1],
            rotate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 360, 0, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faNpm}
            style={{ color: "#c53635" }}
            className="skills__icon"
            data-tip="npm"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.75, 1, 1],
            rotate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 360, 0, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faBootstrap}
            style={{ color: "#348bb3" }}
            className="skills__icon"
            data-tip="Bootstrap"
          />
        </motion.div>
        <motion.div
          animate={{
            scale: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.75, 1],
            rotate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 360, 0],
          }}
          transition={{ repeat: Infinity, duration: 20 }}
        >
          <FontAwesomeIcon
            icon={faFigma}
            style={{ color: "#7e7e7e" }}
            className="skills__icon"
            data-tip="Figma"
          />
        </motion.div>
      </div>
      <ReactTooltip type="info" backgroundColor="#00308F" />
    </div>
  );
};

export default Skills;
