import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";
import { motion } from "framer-motion";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";

const Navigation = () => {
  const [{ isDark }] = useContext(ThemeContext);

  const { pathname } = useLocation();

  return (
    <motion.ul
      className="navigation"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ delay: 1, duration: 1.5 }}
    >
      <li>
        <Link
          to="/"
          className={`navigation__item ${isDark && "navigation__item_dark"} ${
            pathname === "/" && "navigation__item-selected"
          }  
            `}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          to="/projects"
          className={`navigation__item ${isDark && "navigation__item_dark"} 
              ${pathname === "/projects" && "navigation__item-selected"}
            `}
        >
          Projects
        </Link>
      </li>
      <li>
        <Link
          to="/skills"
          className={`navigation__item ${isDark && "navigation__item_dark"} 
              ${pathname === "/skills" && "navigation__item-selected"}
            `}
        >
          Skills
        </Link>
      </li>
      <li>
        <Link
          to="/contact"
          className={`navigation__item ${isDark && "navigation__item_dark"} 
              ${pathname === "/contact" && "navigation__item-selected"}
            `}
        >
          Contact
        </Link>
      </li>
    </motion.ul>
  );
};

export default Navigation;
