import { createContext, useEffect, useState } from 'react';

const themes = {
  dark: {
    backgroundColor: '#101010',
    color: 'white',
  },
  light: {
    backgroundColor: 'white',
    color: 'black',
  },
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsdark] = useState(false);
  const theme = isDark ? themes.dark : themes.light;
  const toggleTheme = () => {
    localStorage.setItem('isDark', JSON.stringify(!isDark));
    setIsdark(!isDark);
  };

  useEffect(() => {
    const isDark = localStorage.getItem('isDark') === 'true';
    setIsdark(isDark);
  }, []);

  return (
    <ThemeContext.Provider value={[{ theme, isDark }, toggleTheme]}>
      {children}
    </ThemeContext.Provider>
  );
};
