import './Projects.css';
import { motion } from 'framer-motion';
import { projectsData } from './ProjectsData';
import { TbWorld } from 'react-icons/tb';
import { AiFillGithub } from 'react-icons/ai';
import { SiExpress, SiMongodb } from 'react-icons/si';
import { DiJavascript1, DiHtml5, DiCss3Full, DiReact, DiNpm } from 'react-icons/di';
import ReactTooltip from 'react-tooltip';
import { useContext } from 'react';
import { ThemeContext } from '../../contexts/theme';

const Projects = () => {
  const [{ isDark }] = useContext(ThemeContext);
  const variants = {
    offscreen: {
      x: 300,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.3,
        duration: 1,
      },
    },
  };
  return (
    <div className="projects">
      {projectsData.map((item, index) => (
        <motion.div
          className="projects__item"
          key={index}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
          variants={variants}
        >
          <div className="projects__left">
            <img
              className={`projects__image ${isDark && 'projects__image-dark'}`}
              src={item.image}
              alt="Project Screenshot"
            />
            <div className="projects__techIcons">
              {item.tech.map((techItem, i) => {
                switch (techItem) {
                  case 'js':
                    return <DiJavascript1 key={i} className="projects__techIcon" />;
                  case 'ht':
                    return <DiHtml5 key={i} className="projects__techIcon" />;
                  case 'cs':
                    return <DiCss3Full key={i} className="projects__techIcon" />;
                  case 're':
                    return <DiReact key={i} className="projects__techIcon" />;
                  case 'ex':
                    return <SiExpress key={i} className="projects__techIcon" />;
                  case 'mo':
                    return <SiMongodb key={i} className="projects__techIcon" />;
                  case 'np':
                    return <DiNpm key={i} className="projects__techIcon" />;
                  default:
                    break;
                }
              })}
            </div>
          </div>
          <div className="projects__description" id={isDark ? 'app_dark-text' : ''}>
            <h3 className="projects__title">{item.name}</h3>
            <a href={item.link} target="_blank" rel="noreferrer" data-tip="Visit">
              <TbWorld className="projects__icon" id={isDark ? 'app_dark-link' : ''} />
            </a>
            <a href={item.github} target="_blank" rel="noreferrer" data-tip="Code">
              <AiFillGithub className="projects__icon" id={isDark ? 'app_dark-link' : ''} />
            </a>
            <p className="projects__text">{item.description}</p>
          </div>
        </motion.div>
      ))}
      <ReactTooltip type="info" backgroundColor="#00308F" />
    </div>
  );
};

export default Projects;
