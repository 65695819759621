import Navigation from "../Navigation/Navigation";
import { motion } from "framer-motion";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";
import { useLocation } from "react-router-dom";
import "./Header.css";
import alon from "../../images/Alon.png";

const Header = () => {
  const [{ isDark }] = useContext(ThemeContext);
  const { pathname } = useLocation();

  return (
    <header className="header">
      {pathname !== "/" ? (
        <motion.img
          src={alon}
          alt="Author"
          className="header__image"
          layoutId="alon"
        />
      ) : (
        <div className="header__image header__image_main-mobile" />
      )}
      <div>
        <motion.h1
          className="header__title"
          id={isDark ? "app_dark-menu" : ""}
          animate={{ y: 0 }}
          initial={{ y: -500 }}
          transition={{ delay: 1 }}
        >
          Alon Goykhman
        </motion.h1>
        <Navigation />
      </div>
    </header>
  );
};

export default Header;
