import { React, useState, useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";

const Contact = () => {
  const [{ isDark }] = useContext(ThemeContext);
  const [sentSuccessMessage, setSentSuccessMessage] = useState("");
  const refForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_da4pq2d",
        "template_mqmssdm",
        refForm.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          setSentSuccessMessage("Message sent. Thank you!");
        },
        (error) => {
          setSentSuccessMessage(error.text);
        }
      );
  };

  return (
    <div className="contact">
      <h2 className="contact__title" id={isDark ? "app_dark-text" : ""}>
        I'd LOVE to hear from you.
      </h2>
      <form ref={refForm} onSubmit={sendEmail}>
        <ul className="contact__list">
          <div className="contact__form-row">
            <li className="contact__form-field">
              <input
                className="contact__form-input"
                type="text"
                name="name"
                placeholder="Your name"
                size="10"
                required
              />
            </li>
            <li className="contact__form-field">
              <input
                className="contact__form-input"
                type="text"
                name="email"
                placeholder="Email"
                required
              />
            </li>
          </div>
          <li className="contact__form-field">
            <input
              className="contact__form-input"
              type="text"
              name="subject"
              placeholder="Subject"
              required
            />
          </li>
          <li className="contact__form-field">
            <textarea
              className="contact__form-input"
              name="message"
              rows="4"
              placeholder="Message"
              required
            />
          </li>
          <div className="contact__form-row">
            <li className="contact__form-field">
              <input
                className="contact__form-input"
                type="submit"
                value="SEND"
              />
            </li>
            <p className="contact__send-message">{sentSuccessMessage}</p>
          </div>
        </ul>
      </form>
    </div>
  );
};

export default Contact;
