import { motion, AnimatePresence } from "framer-motion";
import bigMe from "../../images/big-me.png";
import "./Main.css";

const Main = () => {
  return (
    <div className="main">
      <AnimatePresence>
        <motion.img
          src={bigMe}
          alt="alon"
          className="main__image"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "spring", bounce: 0.5, duration: 1.5 }}
          layoutId="alon"
        />
      </AnimatePresence>
      <motion.div
        className="main__right"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <h2 className="main__title">Welcome!</h2>
        <p>
          I'm a full-stack developer, based in Tel-Aviv, Israel.
          <br />
          <br />
          In addition to my love of technology, I'm also interested in music,
          history, economics and politics :)
        </p>
      </motion.div>
    </div>
  );
};

export default Main;
