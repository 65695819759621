import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import "./ThemeButton.css";
import { FaSun, FaMoon } from "react-icons/fa";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/theme";

const ThemeButton = () => {
  const [{ theme }, toggleTheme] = useContext(ThemeContext);
  const [isOn, setIsOn] = useState(false);
  const toggleSwitch = () => {
    toggleTheme();
    setIsOn(!isOn);
  };

  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };

  return (
    <motion.div
      className="switch"
      check={isOn.toString()}
      onClick={toggleSwitch}
      animate={{ y: 0 }}
      initial={{ y: -500 }}
      transition={{ delay: 2 }}
    >
      <motion.div src="" className="handle" layout transition={spring}>
        {isOn ? <FaMoon className="handle" /> : <FaSun className="handle" />}
      </motion.div>
    </motion.div>
  );
};

export default ThemeButton;
